
<template>
  <sun-burst-view :data="data" />
</template>

<script lang="ts" setup>
import SunBurstView from "@/components/SunBurstView.vue";

/**
 * Current data.
 */
export const data = {
  name: "DFØ",
  children: require("@/assets/data/dfo-employees.json")
};

export default { components: { SunBurstView } };
</script>
