import { defineComponent as __define__ } from 'vue'
import { Slots as __Slots__ } from 'vue'
import SunBurstView from "@/components/SunBurstView.vue";

/**
 * Current data.
 */

export function setup() {

const data = {
  name: "DFØ",
  children: require("@/assets/data/dfo-employees.json")
};


return { data }
}

const __default__ = { components: { SunBurstView } };
export default __define__({
  ...__default__,
  setup
})